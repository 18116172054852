import { render, staticRenderFns } from "./GameUserAgreement.vue?vue&type=template&id=69084d93&scoped=true"
import script from "./GameUserAgreement.vue?vue&type=script&lang=js"
export * from "./GameUserAgreement.vue?vue&type=script&lang=js"
import style0 from "./GameUserAgreement.vue?vue&type=style&index=0&id=69084d93&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69084d93",
  null
  
)

export default component.exports