<template>
    <div class="privacy">
      <p class="privacy-top">面具约会-隐私政策</p>
      <hr/>
      <span class="word">请务必认真阅读和理解本《用户服务协议》（以下简称《协议》）中规定的所有权利和限制。除非您接受本《协议》条款，否则您无权注册、登录或使用本协议所涉及的相关服务。您一旦注册、登录、使用或以任何方式使用本《协议》所涉及的相关服务的行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意本《协议》中的条款，请不要注册、登录或使用本《协议》相关服务。</span>
      <hr/>
      <p class="title">1. 服务内容</p>
      <span class="word">1.1 我们完全按照其发布的服务条款和操作规则提供基于互联网以及移动互联网的相关服务（以下简称网络服务）</span>
      <br/>
      <span class="word">1.2 用户理解并接受，我们仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网 费、为使用移动网而支付的手机费）均应由用户自行负担。</span>
      <br/>
      <span class="word">1.3 为了给您提供免费且高质量的服务，我们会在您使用本产品的过程中展示广告。</span>
      <br/>
      <span class="word">1.4 我们还给您提供了屏保服务。在您手机闲置时，会自动启动屏保服务，以延长您手机屏幕的使用寿命</span>
      <br/>
      <span class="word">1.5 客服QQ:279055666</span>
      <hr/>
      <p class="title">2. 用户使用规则</p>
      <span class="word">2.1 用户在申请使用网络服务时，必须向我们提供准确的个人资料，如个人资料有任何变动，必须及时更新。因用户提供个人资料不准确、不真实而引发的一切后果由用户承担。</span>
      <br/>
      <span class="word">2.2 用户不应将其帐号、密码转让、出借或以任何脱离用户控制的形式交由他人使用。如用户发现其帐号遭他人非法使用，应立即通知我们。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，我们不承担任何责任。</span>
      <br/>
      <span class="word">2.3 用户应当为自身注册帐户下的一切行为负责，因用户行为而导致的用户自身或其他任何第三方的任何损失或损害，我们不承担责任。</span>
      <br/>
      <span class="word">2.4 用户理解并接受我网提供的服务中可能包括广告，用户同意在使用过程中显示我们和第三方供应商、合作伙伴提供的广告。</span>
      <br/>
      <span class="word">2.5 用户在使用我们网络服务过程中，必须遵循以下原则：</span>
      <br/>
      <span class="word">2.5.1 遵守中国有关的法律和法规；</span>
      <br/>
      <span class="word">2.5.2 遵守所有与网络服务有关的网络协议、规定和程序；</span>
      <br/>
      <span class="word">2.5.3 不得为任何非法目的而使用网络服务系统；</span>
      <br/>
      <span class="word">2.5.4 不得利用我们网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；</span>
      <br/>
      <span class="word">2.5.5 不得利用我们提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</span>
      <br/>
      <span class="word">2.5.6 不得侵犯我们和其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</span>
      <br/>
      <span class="word">2.5.7 不得利用我们网络服务系统进行任何不利于我们的行为；</span>
      <br/>
      <span class="word">2.5.8 如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告我们。</span>
      <br/>
      <span class="word">2.6 如用户在使用网络服务时违反任何上述规定，我们或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户收藏的内容等、暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。</span>
      <hr/>
      <p class="title">3. 服务变更、中断或终止</p>
      <span class="word">3.1 鉴于网络服务的特殊性，用户同意我们有权根据业务发展情况随时变更、中断或终止部分或全部的网络服务而无需通知用户，也无需对任何用户或任何第三方承担任何责任；</span>
      <br/>
      <span class="word">3.2 用户理解，我们需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，我们无需为此承担任何责任，但我们应尽可能事先进行通告。</span>
      <br/>
      <span class="word">3.3 如发生下列任何一种情形，我们有权随时中断或终止向用户提供本《协议》项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任：</span>
      <br/>
      <span class="word">3.3.1 用户提供的个人资料不真实；</span>
      <br/>
      <span class="word">3.3.2 用户违反本《协议》中规定的使用规则。</span>
      <hr/>
      <p class="title">4. 知识产权</p>
      <span class="word">4.1 我们提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得用于任何商业目的。</span>
      <br/>
      <span class="word">4.2 我们为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverseengineer）、反向编译（decompile）或反汇编（disassemble）。</span>
      <hr/>
      <p class="title">5. 隐私保护</p>
      <span class="word">5.1 保护用户隐私是我们的一项基本政策，我们保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在我们的非公开内容，但下列情况除外：</span>
      <br/>
      <span class="word">5.1.1 事先获得用户的明确授权；</span>
      <br/>
      <span class="word">5.1.2 根据有关的法律法规要求；</span>
      <br/>
      <span class="word">5.1.3 按照相关政府主管部门的要求；</span>
      <br/>
      <span class="word">5.1.4 为维护社会公众的利益；</span>
      <br/>
      <span class="word">5.1.5 为维护我们的合法权益。</span>
      <br/>
      <span class="word">5.2 我们可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与我们同等的保护用户隐私的责任，则我们有权将用户的注册资料等提供给该第三方。</span>
      <br/>
      <span class="word">5.3 在不透露单个用户隐私资料的前提下，我们有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</span>
      <br/>
      <span class="word">5.4 我们制定了以下四项隐私权保护原则，指导我们如何来处理产品中涉及到用户隐私权和用户信息等方面的问题： （1）利用我们收集的信息为用户提供有价值的产品和服务。 （2）开发符合隐私权标准和隐私权惯例的产品。 （3）将个人信息的收集透明化，并由权威第三方监督。 （4）尽最大的努力保护我们掌握的信息。 您可通过我们网站查看我们有关隐私保护的详细内容</span>
      <hr/>
      <p class="title">6. 免责声明</p>
      <span class="word">6.1 我们不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</span>
      <br/>
      <span class="word">6.2 我们不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由我们实际控制的任何网页上的内容，我们不承担任何责任。</span>
      <br/>
      <span class="word">6.3 对于因电信系统或互联网网络故障、计算机故障或病毒、信息损坏或丢失、计算机系统问题或其它任何不可抗力原因而产生损失，我们不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</span>
      <br/>
      <span class="word">6.4 我们有权但无义务，改善或更正本服务任何部分之任何疏漏、错误。</span>
      <hr/>
      <p class="title">7. 法律及争议解决</p>
      <span class="word">7.1 本协议适用中华人民共和国法律。</span>
      <br/>
      <span class="word">7.2 因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成的，任何一方均可将有关争议提交至北京仲裁委员会并按照其届时有效的仲裁规则仲裁；仲裁裁决是终局的，对各方均有约束力。</span>
      <hr/>
      <p class="title">8. 其他条款</p>
      <span class="word">8.1 如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。</span>
      <br/>
      <span class="word">8.2 我们有权随时根据有关法律、法规的变化以及公司经营状况和经营策略的调整等修改本协议，而无需另行单独通知用户。修改后的协议会在我们网站上公布。用户可随时通过我们网站浏览最新服务协议条款。当发生有关争议时，以最新的协议文本为准。如果不同意我们对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受我们对本协议相关条款所做的修改。</span>
      <br/>
      <span class="word">8.3 用户对服务之任何部分或本服务条款的任何部分之意见及建议可通过客户服务部门与我们联系，我们保留本服务条款之解释权与修改权。</span>
    </div>
</template>

<script>
export default {
    name: "GameUserAgreement",
    data() {
        return {
        }
    },
}
</script>

<style scoped>
.privacy {
  text-align: initial;
  padding: 15px 25px;
}
.privacy-top {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0 7px;
}
.word {
  font-size: 14px;
  text-align: left;
  word-break: break-all;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 8px;
}
</style>
